import { Routes, Route } from "react-router-dom";
import React, { Suspense, lazy, ReactNode } from "react";
import { HasPermission } from "@components/HasPermission";

const MarketPrice = lazy(() => import("../pages/MarketPrice"));
const AddMarketPrice = lazy(() => import("../pages/AddMarketPrice"));
const Changelog = lazy(() => import("../pages/Changelog"));

interface IRoute {
  route: string;
  element: ReactNode;
}

export const routes: Array<IRoute> = [
  {
    route: "/en/market-price",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <MarketPrice />
      </Suspense>
    ),
  },
  {
    route: "/en/market-price/:stockId/add",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AddMarketPrice />
      </Suspense>
    ),
  },
  {
    route: "/en/market-price/:stockId/changelog",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Changelog />
      </Suspense>
    ),
  },
];

export const Layout: React.FC = () => {
  return (
    <div>
      <HasPermission
        permission="canViewPages"
        fallback="You have no access right"
      >
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {routes.map(({ route, element }) => (
              <Route key={route} path={route} element={element} />
            ))}
            <Route path="*" element="Page is not found" />
          </Routes>
        </Suspense>
      </HasPermission>
    </div>
  );
};
