import { graphql } from "msw";
import casual from "casual-browserify";

export const getPermissionsResponse = graphql.query(
  "GetPermissions",
  (_req, res, ctx) => {
    return res(
      ctx.data({
        getPermissions: {
          canViewPages: true,
          canToggleMarketPriceEntry: true,
        },
      })
    );
  }
);

export const getStockDetailsResponse = graphql.query(
  "GetStockDetails",
  (_req, res, ctx) => {
    return res(
      ctx.data({
        getStockDetails: {
          stockNumber: casual.uuid,
          make: casual.string,
          model: casual.string,
          registrationDate: casual.date(),
          mileage: casual.integer(1, 8),
          fuelType: casual.string,
          gearType: casual.string,
          kw: casual.integer(1, 10),
          horsePower: casual.integer(1, 10),
          currentPriceInMajorUnits: casual.integer(1, 10),
          imageUrl:
            "https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/21c0550-001-1630936200.jpg?crop=1xw:0.9xh;center,top&resize=480:*",
          currencyCode: casual.string,
        },
      })
    );
  }
);

export const getMarketPriceEntriesResponse = graphql.query(
  "GetMarketPriceEntries",
  (_req, res, ctx) => {
    return res(
      ctx.data({
        getMarketPriceEntries: [
          {
            id: casual.uuid,
            stockNumber: casual.uuid,
            carPositionPage: casual.integer(1, 10),
            carPositionListing: casual.integer(1, 10),
            searchUrl: casual.url,
            comment: casual.description,
            marketPriceMinorUnits: casual.integer(1, 10),
            pricesInMajorUnits: casual.array_of_integers(10),
            disabledForDownpricing: casual.boolean,
            expired: casual.boolean,
            submissionDate: casual.date(),
            userName: casual.username,
            selectedForDownpricing: casual.boolean,
          },
        ],
      })
    );
  }
);

export const toggleEntryResponse = graphql.mutation(
  "ToggleEntry",
  (_req, res, ctx) => {
    return res(
      ctx.data({
        toggleEntry: casual.boolean,
      })
    );
  }
);

export const createMarketPriceEntryResponse = graphql.mutation(
  "CreateMarketPriceEntry",
  (_req, res, ctx) => {
    return res(
      ctx.data({
        createMarketPriceEntry: {
          stockNumber: casual.uuid,
          carPositionPage: casual.integer(1, 10),
          carPositionListing: casual.integer(1, 10),
          searchUrl: casual.url,
          comment: casual.description,
          marketPriceMinorUnits: casual.integer(1, 10),
          uniqueIdentifier: casual.string,
          pricesInMajorUnits: casual.array_of_integers(),
          enabled: casual.boolean,
          expired: casual.boolean,
          submissionDate: casual.date(),
          user: casual.username,
        },
      })
    );
  }
);

export const handlers = [
  getPermissionsResponse,
  getStockDetailsResponse,
  getMarketPriceEntriesResponse,
  toggleEntryResponse,
  createMarketPriceEntryResponse,
];
