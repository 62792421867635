export enum DataQaSelector {
  marketPricePageContainer = "marketPricePageContainer",
  changelogPageContainer = "changelogPageContainer",
  addMarketPricePageContainer = "addMarketPricePageContainer",
  stockIdInput = "stockIdInput",
  navigateToAddPrice = "navigateToAddPrice",
  navigateToChangeLog = "navigateToChangeLog",
  pageTitle = "pageTitle",
  loader = "loader",
  entryStockID = "entryStockID",
  entryCarPositionPage = "entryCarPositionPage",
  entryCarPositionListing = "entryCarPositionListing",
  entryURL = "entryURL",
  entryCarPrices = "entryCarPrices",
  entryDerivedMarketPrice = "entryDerivedMarketPrice",
  entryId = "entryId",
  entryComment = "entryComment",
  entryTitle = "entryTitle",
  entryStatus = "entryStatus",
  entryStatusEnabled = "entryStatusEnabled",
  entryStatusDisabled = "entryStatusDisabled",
  entryHeader = "entryHeader",
  noData = "noData",
  successNotification = "successNotification",
  errorNotification = "errorNotification",
  marketPriceLoadingSpinner = "marketPriceLoadingSpinner",
  marketPriceEntryForm = "marketPriceEntryForm",
  stockDetails = "stockDetails",
  stockNumber = "stockNumber",
  stockNumberInput = "stockNumberInput",
  carPositionPage = "carPositionPage",
  carPositionPageInput = "carPositionPageInput",
  carPositionListing = "carPositionListing",
  carPositionListingInput = "carPositionListingInput",
  searchUrl = "searchUrl",
  searchUrlInput = "searchUrlInput",
  comment = "comment",
  commentInput = "commentInput",
  submitButton = "submitButton",
  marketPriceEntryFormSection = "marketPriceEntryFormSection",
  stockDetailsSection = "stockDetailsSection",
  stockInformation = "stockInformation",
  stockInformationStockId = "stockInformationStockId",
  stockInformationMake = "stockInformationMake",
  stockInformationModel = "stockInformationModel",
  stockInformationRegistrationDate = "stockInformationRegistrationDate",
  stockInformationMileage = "stockInformationMileage",
  stockInformationFuelType = "stockInformationFuelType",
  stockInformationGearType = "stockInformationGearType",
  stockInformationKW = "stockInformationKW",
  stockInformationHP = "stockInformationHP",
  stockInformationCurrentCarPrice = "stockInformationCurrentCarPrice",
  stockInformationImage = "stockInformationImage",
  marketPriceValidationWarnings = "marketPriceValidationWarnings",
  warningModalCancelButton = "warningModalCancelButton",
  warningModalSaveButton = "warningModalSaveButton",
  selectedForDownpricingLabel = "selectedForDownpricingLabel",
}
