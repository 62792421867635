import { ErrorResponse, onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { ServerError } from "@apollo/client/link/utils";
import fetch from "cross-fetch";

import typePolicies from "./policies";

function getCookieByName(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(";").shift();
  }
}

const httpLink = createHttpLink({
  uri: "/api/v1/retail-market-price-gql/graphql",
  fetch,
});

const testHttpLink = createHttpLink({
  uri: "https://develop.qa.backoffice.retail.auto1.clo/api/v1/retail-market-price-gql/graphql",
  fetch,
});

const authLink = setContext(() => {
  const accessToken = getCookieByName("t");

  return {
    headers: {
      accept: "application/json, text/plain, */*",
      "content-type": "application/json;charset=UTF-8",
      authorization: `Bearer ${accessToken}`,
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, onError(handleErrors), httpLink]),
  cache: new InMemoryCache({ typePolicies }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
  },
});

export const testClient = new ApolloClient({
  link: ApolloLink.from([authLink, onError(handleErrors), testHttpLink]),
  cache: new InMemoryCache({ typePolicies }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
  },
});

function handleErrors({
  networkError,
  operation,
  forward,
}: ErrorResponse): void {
  if ((networkError as ServerError)?.statusCode === 401) {
    const accessToken = getCookieByName("t");
    operation.setContext({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    forward(operation);
  }
}
