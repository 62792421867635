import { FC, ReactNode } from "react";
import { Spin } from "antd";
import {
  useGetPermissionsQuery,
  MarketPricePermissions,
} from "@src/apollo/gql-types";
import { DataQaSelector } from "@enums/DataQaSelector";

interface Props {
  permission: keyof Omit<MarketPricePermissions, "__typename">;
  fallback?: ReactNode;
}

export const HasPermission: FC<Props> = ({
  permission,
  fallback = <></>,
  children,
}) => {
  const { data, loading } = useGetPermissionsQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
  });

  return (
    <>
      <Spin spinning={loading} data-qa-selector={DataQaSelector.loader} />
      {loading ? null : data?.getPermissions[permission] ? children : fallback}
    </>
  );
};
