import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type MarketPriceEntry = {
  __typename?: "MarketPriceEntry";
  carPositionListing: Scalars["String"];
  carPositionPage: Scalars["Int"];
  comment: Maybe<Scalars["String"]>;
  disabledForDownpricing: Scalars["Boolean"];
  expired: Scalars["Boolean"];
  id: Scalars["String"];
  marketPriceMinorUnits: Scalars["Int"];
  pricesInMajorUnits: Maybe<Array<Scalars["Int"]>>;
  searchUrl: Scalars["String"];
  selectedForDownpricing: Scalars["Boolean"];
  stockNumber: Scalars["String"];
  submissionDate: Scalars["String"];
  userName: Scalars["String"];
};

export type MarketPriceEntryInput = {
  carPositionListing: Scalars["String"];
  carPositionPage: Scalars["Int"];
  comment?: InputMaybe<Scalars["String"]>;
  pricesInMajorUnits: Array<Scalars["Int"]>;
  searchUrl: Scalars["String"];
  stockNumber: Scalars["String"];
};

export type MarketPricePermissions = {
  __typename?: "MarketPricePermissions";
  canToggleMarketPriceEntry: Scalars["Boolean"];
  canViewPages: Scalars["Boolean"];
};

export type Mutation = {
  __typename?: "Mutation";
  createMarketPriceEntry: Scalars["Boolean"];
  toggleEntry: Scalars["Boolean"];
};

export type MutationCreateMarketPriceEntryArgs = {
  marketPrice: MarketPriceEntryInput;
};

export type MutationToggleEntryArgs = {
  disabledForDownpricing: Scalars["Boolean"];
  entryId: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  getMarketPriceEntries: Array<Maybe<MarketPriceEntry>>;
  getPermissions: MarketPricePermissions;
  getStockDetails: StockDetails;
};

export type QueryGetMarketPriceEntriesArgs = {
  stockNumber: Scalars["String"];
};

export type QueryGetStockDetailsArgs = {
  stockNumber: Scalars["String"];
};

export type StockDetails = {
  __typename?: "StockDetails";
  currencyCode: Scalars["String"];
  currentPriceInMajorUnits: Scalars["Int"];
  fuelType: Scalars["String"];
  gearType: Scalars["String"];
  horsePower: Scalars["Int"];
  imageUrl: Maybe<Scalars["String"]>;
  kw: Scalars["Int"];
  make: Scalars["String"];
  mandatoryPriceCount: Scalars["Int"];
  mileage: Scalars["Int"];
  model: Scalars["String"];
  registrationDate: Scalars["String"];
  stockNumber: Scalars["String"];
};

export type CreateMarketPriceEntryMutationVariables = Exact<{
  marketPrice: MarketPriceEntryInput;
}>;

export type CreateMarketPriceEntryMutation = {
  __typename?: "Mutation";
  createMarketPriceEntry: boolean;
};

export type ToggleEntryMutationVariables = Exact<{
  entryId: Scalars["String"];
  disabledForDownpricing: Scalars["Boolean"];
}>;

export type ToggleEntryMutation = {
  __typename?: "Mutation";
  toggleEntry: boolean;
};

export type GetMarketPriceEntriesQueryVariables = Exact<{
  stockNumber: Scalars["String"];
}>;

export type GetMarketPriceEntriesQuery = {
  __typename?: "Query";
  getStockDetails: { __typename?: "StockDetails"; currencyCode: string };
  getMarketPriceEntries: Array<{
    __typename?: "MarketPriceEntry";
    id: string;
    stockNumber: string;
    carPositionPage: number;
    carPositionListing: string;
    searchUrl: string;
    comment: string | null;
    marketPriceMinorUnits: number;
    pricesInMajorUnits: Array<number> | null;
    disabledForDownpricing: boolean;
    expired: boolean;
    submissionDate: string;
    userName: string;
    selectedForDownpricing: boolean;
  } | null>;
};

export type GetPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPermissionsQuery = {
  __typename?: "Query";
  getPermissions: {
    __typename?: "MarketPricePermissions";
    canViewPages: boolean;
    canToggleMarketPriceEntry: boolean;
  };
};

export type GetStockDetailsQueryVariables = Exact<{
  stockNumber: Scalars["String"];
}>;

export type GetStockDetailsQuery = {
  __typename?: "Query";
  getStockDetails: {
    __typename?: "StockDetails";
    stockNumber: string;
    make: string;
    model: string;
    registrationDate: string;
    mileage: number;
    fuelType: string;
    gearType: string;
    kw: number;
    horsePower: number;
    currentPriceInMajorUnits: number;
    imageUrl: string | null;
    currencyCode: string;
    mandatoryPriceCount: number;
  };
};

export const CreateMarketPriceEntryDocument = gql`
  mutation CreateMarketPriceEntry($marketPrice: MarketPriceEntryInput!) {
    createMarketPriceEntry(marketPrice: $marketPrice)
  }
`;
export type CreateMarketPriceEntryMutationFn = Apollo.MutationFunction<
  CreateMarketPriceEntryMutation,
  CreateMarketPriceEntryMutationVariables
>;

/**
 * __useCreateMarketPriceEntryMutation__
 *
 * To run a mutation, you first call `useCreateMarketPriceEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarketPriceEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarketPriceEntryMutation, { data, loading, error }] = useCreateMarketPriceEntryMutation({
 *   variables: {
 *      marketPrice: // value for 'marketPrice'
 *   },
 * });
 */
export function useCreateMarketPriceEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMarketPriceEntryMutation,
    CreateMarketPriceEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMarketPriceEntryMutation,
    CreateMarketPriceEntryMutationVariables
  >(CreateMarketPriceEntryDocument, options);
}
export type CreateMarketPriceEntryMutationHookResult = ReturnType<
  typeof useCreateMarketPriceEntryMutation
>;
export type CreateMarketPriceEntryMutationResult =
  Apollo.MutationResult<CreateMarketPriceEntryMutation>;
export type CreateMarketPriceEntryMutationOptions = Apollo.BaseMutationOptions<
  CreateMarketPriceEntryMutation,
  CreateMarketPriceEntryMutationVariables
>;
export const ToggleEntryDocument = gql`
  mutation ToggleEntry($entryId: String!, $disabledForDownpricing: Boolean!) {
    toggleEntry(
      entryId: $entryId
      disabledForDownpricing: $disabledForDownpricing
    )
  }
`;
export type ToggleEntryMutationFn = Apollo.MutationFunction<
  ToggleEntryMutation,
  ToggleEntryMutationVariables
>;

/**
 * __useToggleEntryMutation__
 *
 * To run a mutation, you first call `useToggleEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleEntryMutation, { data, loading, error }] = useToggleEntryMutation({
 *   variables: {
 *      entryId: // value for 'entryId'
 *      disabledForDownpricing: // value for 'disabledForDownpricing'
 *   },
 * });
 */
export function useToggleEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleEntryMutation,
    ToggleEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleEntryMutation, ToggleEntryMutationVariables>(
    ToggleEntryDocument,
    options
  );
}
export type ToggleEntryMutationHookResult = ReturnType<
  typeof useToggleEntryMutation
>;
export type ToggleEntryMutationResult =
  Apollo.MutationResult<ToggleEntryMutation>;
export type ToggleEntryMutationOptions = Apollo.BaseMutationOptions<
  ToggleEntryMutation,
  ToggleEntryMutationVariables
>;
export const GetMarketPriceEntriesDocument = gql`
  query GetMarketPriceEntries($stockNumber: String!) {
    getStockDetails(stockNumber: $stockNumber) {
      currencyCode
    }
    getMarketPriceEntries(stockNumber: $stockNumber) {
      id
      stockNumber
      carPositionPage
      carPositionListing
      searchUrl
      comment
      marketPriceMinorUnits
      pricesInMajorUnits
      disabledForDownpricing
      expired
      submissionDate
      userName
      selectedForDownpricing
    }
  }
`;

/**
 * __useGetMarketPriceEntriesQuery__
 *
 * To run a query within a React component, call `useGetMarketPriceEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketPriceEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketPriceEntriesQuery({
 *   variables: {
 *      stockNumber: // value for 'stockNumber'
 *   },
 * });
 */
export function useGetMarketPriceEntriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMarketPriceEntriesQuery,
    GetMarketPriceEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMarketPriceEntriesQuery,
    GetMarketPriceEntriesQueryVariables
  >(GetMarketPriceEntriesDocument, options);
}
export function useGetMarketPriceEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMarketPriceEntriesQuery,
    GetMarketPriceEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMarketPriceEntriesQuery,
    GetMarketPriceEntriesQueryVariables
  >(GetMarketPriceEntriesDocument, options);
}
export type GetMarketPriceEntriesQueryHookResult = ReturnType<
  typeof useGetMarketPriceEntriesQuery
>;
export type GetMarketPriceEntriesLazyQueryHookResult = ReturnType<
  typeof useGetMarketPriceEntriesLazyQuery
>;
export type GetMarketPriceEntriesQueryResult = Apollo.QueryResult<
  GetMarketPriceEntriesQuery,
  GetMarketPriceEntriesQueryVariables
>;
export const GetPermissionsDocument = gql`
  query GetPermissions {
    getPermissions {
      canViewPages
      canToggleMarketPriceEntry
    }
  }
`;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options
  );
}
export function useGetPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options
  );
}
export type GetPermissionsQueryHookResult = ReturnType<
  typeof useGetPermissionsQuery
>;
export type GetPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetPermissionsLazyQuery
>;
export type GetPermissionsQueryResult = Apollo.QueryResult<
  GetPermissionsQuery,
  GetPermissionsQueryVariables
>;
export const GetStockDetailsDocument = gql`
  query GetStockDetails($stockNumber: String!) {
    getStockDetails(stockNumber: $stockNumber) {
      stockNumber
      make
      model
      registrationDate
      mileage
      fuelType
      gearType
      kw
      horsePower
      currentPriceInMajorUnits
      imageUrl
      currencyCode
      mandatoryPriceCount
    }
  }
`;

/**
 * __useGetStockDetailsQuery__
 *
 * To run a query within a React component, call `useGetStockDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockDetailsQuery({
 *   variables: {
 *      stockNumber: // value for 'stockNumber'
 *   },
 * });
 */
export function useGetStockDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStockDetailsQuery,
    GetStockDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStockDetailsQuery, GetStockDetailsQueryVariables>(
    GetStockDetailsDocument,
    options
  );
}
export function useGetStockDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockDetailsQuery,
    GetStockDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStockDetailsQuery,
    GetStockDetailsQueryVariables
  >(GetStockDetailsDocument, options);
}
export type GetStockDetailsQueryHookResult = ReturnType<
  typeof useGetStockDetailsQuery
>;
export type GetStockDetailsLazyQueryHookResult = ReturnType<
  typeof useGetStockDetailsLazyQuery
>;
export type GetStockDetailsQueryResult = Apollo.QueryResult<
  GetStockDetailsQuery,
  GetStockDetailsQueryVariables
>;
